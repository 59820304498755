import {Translator} from "../../services/translation/translation-service";
import {createContext} from "preact";

interface Context {
  countryCode: string;
  email: string;
  i18n: Translator;
  actions: Actions
}

interface Actions {
  validateEmail: (email: string) => boolean;
  trackEvent: (event: EventDetails) => void;
  dispatchGlobalEvent: (event: string) => void;
}

export const WidgetContext = createContext<Context>(null);

export const NewsletterTrackingEvent = 'NEWSLETTER_TRACKING_EVENT';
export const NewsletterSuccessEvent = 'NEWSLETTER_SUCCESS_EVENT';

export interface EventDetails {
  action: EventAction
}
export enum EventAction {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export function isBirthdayCouponCountry(countryCode: string) {
  return !["ae", "am", "az", "bg", "by", "de", "eg", "ge", "hu", "ma", "om", "qa", "sa", "sk"].includes(countryCode);
}
